import onLoad from '../../../../static/js/onLoad';

function hideAll() {
  const toHideSlideOvers = document.querySelectorAll(
    '._slide-over-panel:not(._translate-x-full)'
  );
  for (let i = 0; i < toHideSlideOvers.length; ++i) {
    const parent = toHideSlideOvers[i].closest('[role="dialog"]');
    toHideSlideOvers[i].classList.remove('_translate-x-0', '_drop-shadow-md');
    toHideSlideOvers[i].classList.add('_translate-x-full');

    setTimeout(() => {
      // To ensure the exit-transition finishes before hiding the menu,
      // this need to be coordinated with the `_duration-300` class
      // on the SlideOver() macro.
      parent.classList.add('_hidden');
    }, 300);
  }

  // For detecting from other places when the slide-over is closed
  document.dispatchEvent(new Event('slideOverClosed'));
}

function correctImageAspectRatio(images) {
  // This is a function to add padding to vertical images so that they don't
  // take up the entire slide-over.

  if (!images) {
    return;
  }

  for (let i = 0; i < images.length; i++) {
    const image = images[i];
    if (
      image.classList.contains('_object-cover') &&
      image.width / image.height < 1
    ) {
      // Add padding to the wrapping div
      image.closest('div').classList.add('_px-20');
    }
  }
}
let currentContainer = null;

function handleClickOutside(e) {
  const toastContainer = document.querySelector('#toasts-container');
  if (toastContainer && toastContainer.contains(e.target)) {
    return;
  }

  if (currentContainer && !currentContainer.contains(e.target)) {
    hideAll();
  }
}

function handleKeydown(keyPressEvent) {
  // Let users press escape to remove the dialog
  const ESC_KEY = 27;
  if (currentContainer && keyPressEvent.keyCode === ESC_KEY) {
    hideAll();
  }
}

function show(event: Event) {
  event.stopPropagation();

  // This ID need to match the derived SlideOver() ID in
  // cloud/rainbow/jinja2/rainbow/components/slideOver/macros.html

  const slideOverContainer = document.getElementById(`${this.id}-container`);
  currentContainer = slideOverContainer;
  const slideOverParent = slideOverContainer.closest('[role="dialog"]');
  const isAlreadyVisible =
    slideOverContainer.classList.contains('_translate-x-0');

  const images = slideOverContainer.querySelectorAll('img');
  correctImageAspectRatio(images);

  if (slideOverContainer) {
    if (!isAlreadyVisible) {
      hideAll();

      window.requestAnimationFrame(() => {
        // First we remove the hidden class...
        slideOverParent.classList.remove('_hidden');
        window.requestAnimationFrame(() => {
          // Then we translate it out.
          slideOverContainer.classList.remove('_translate-x-full');
          slideOverContainer.classList.add('_translate-x-0', '_drop-shadow-md');
        });
      });
    } else {
      // Clicking the trigger after it's open, should close it again.
      hideAll();
    }
  }
}

function hide() {
  hideAll();
}

function createEventsForFragment(frag) {
  const triggerShowElements = frag.querySelectorAll(
    '._slide-over-show-trigger'
  );
  for (let i = 0; i < triggerShowElements.length; ++i) {
    triggerShowElements[i].addEventListener('click', show);
  }

  const triggerHideElements = frag.querySelectorAll(
    '._slide-over-hide-trigger'
  );
  for (let i = 0; i < triggerHideElements.length; ++i) {
    triggerHideElements[i].addEventListener('click', hide);
  }
}

function createEvents() {
  createEventsForFragment(document);
  window.addEventListener('keydown', handleKeydown);
  window.addEventListener('click', handleClickOutside);
  window.addEventListener('hideAllSlideOvers', hideAll);
}

onLoad(() => {
  createEvents();
  if (typeof htmx !== 'undefined') {
    htmx.onLoad((target) => {
      // Add events for any slideover elemnts in the htmx fragment
      createEventsForFragment(target);
    });
  }
});
